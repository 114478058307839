<template>
  <div class="WJgroupHonor">
    <headert></headert>
    <el-row class="content">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img src="../../assets/image/info/banner2.png" alt="">
        </div>
      </el-col>
    </el-row>
    <div>
      <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
      <el-row class=" WJgroupIntroduce-Box" type="flex" justify="center" align='center'>
        <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">

          <div class="padding-tb70">
            <div class="WJgroup-titleBox flex">
              <p class="WJgroup-title">企业软文</p>
              <!-- <p class="WJgroup-subtitle">WEIGA Group Introduction</p> -->
            </div>
          </div>

          <div class="WJgroupHonor-time">
            <div class="flex align-start">
              <ul class="articlesType">
                <li :class="typeIndex==index?'activeAtype':''" v-for="(item,index) in yearList" @click="choseType(index,item)">
                  <span class="activeAName">{{item.name}}<span class='activeAtSpan'></span></span>
                </li>

              </ul>
              <div class="articlesContent">
                <div class="articlesYears flex">
                  <p class="articlesYName " :class="yearIndex=='全部'?'activeY':''" @click="choseYear('全部')">全部</p>
                  <div>
                    <ul class="flex">
                      <li class="articlesYName" :class="yearIndex==item?'activeY':''" v-for="item in newsYear" @click="choseYear(item)">{{item}}</li>
                    </ul>
                  </div>

                </div>
                <ul class="articlesList flex">
                  <li v-for='(item,index) in contentList'>
                      <a :href="item.pdfurl" target="_blank">
                    <div>
                      <img :src="item.litpic" alt="">
                    </div>
                    <p>{{item.title}}</p>
                    </a>
                  </li>
                </ul>


              </div>
            </div>
           <!-- <div>
              <div style="overflow: hidden;width: 100%;overflow-x: scroll;" ref="atlasulBox">
                <div style="transition: 1s;"
                  :style="{width:ulBoxwidth*3+'px','transform':' translate('+left1+'px,0px)'}">
                  <div v-for="(item,index) in yearList" :key="index" style="display: inline-block;"
                    :style="{width:ulBoxwidth+'px'}">
                    <swiper :options="swiperOptions" class="nativeContent-ulBox">
                      <swiper-slide v-for="(item,index) in item.list" :key="index" class="nativeContent-ul">
                        <li>
                          <p>{{item.title}}</p>
                          <div>
                            <img :src="item.litpic" alt="">
                          </div>
                        </li>
                      </swiper-slide>
                      <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                    <div class="WJgroupHonor-taxisType" :style="{width:ulBoxwidth+'px'}">{{item.name}}</div>
                  </div>


                </div>
              </div>
              <div class="WJgroupHonor-taxisother">

                <div class="WJgroupHonor-leftIcon WJgroupHonor-Icon" @click="moveRight">
                  <i class="el-icon-arrow-left"></i>
                </div>
                <div class="WJgroupHonor-rightIcon WJgroupHonor-Icon" @click="moveLeft">

                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div> -->
          </div>
        </el-col>

      </el-row>
    </div>
    <el-row>

      <el-col>
        <footerb></footerb>
      </el-col>

    </el-row>
    <!-- 底部 -->

  </div>
</template>

<script>
  export default {
    name: "WJgroupIntroduce",
    components: {},
    data() {
      return {
        menuActiveIndex: '/nativeContent',
        menu: {
          name: '资讯中心',
          path: '/Information',
          list: [{
            name: '集团动态',
            path: '/Information/1',
          }, {
            name: '企业资讯',
            path: '/Information/2',
          }]
        },
        breadcrumb: [{
          name: '资讯中心',
          path: '/Information',
        }, {
          name: '企业软文',
          path: '/nativeContent',
        }],

        yearList: [],
        yearIndex: 0,
        liBoxwidth: 180,
        ulwidth: null,
        leftMove: 0,
        swiperOptions: {
          slidesPerView: 5,
          spaceBetween: 30,
          // autoplay: {
          //   delay: 3000,
          //   disableOnInteraction: true
          // },

          // loop: true,
          // effect: 'fade',
          // fadeEffect: {
          //   crossFade: true,
          // },
          // fade: {
          //   slideShadows: true,
          //   shadow: true,
          //   shadowOffset: 100,
          //   shadowScale: 0.6,
          //   crossFade: true
          // },

          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true
            // progressbarOpposite: true,
          }
        },
        left1: 0,

        ulBoxwidth: 0,
        indexOne: 1,

        typeid: 82,
        typeList: [],
        newsList: [],
        typeIndex:0,
        contentList:[],
        newsYear:[],
        yearIndex:"全部"
      };
    },
    computed: {},
    created() {
      this.getTypeList();
    },
    mounted() {

    },
    methods: {
      choseYear(item){
        this.yearIndex=item;
        let list=this.yearList[this.typeIndex].list;
        if(item=="全部"){
           this.contentList=list
        }else{
          this.contentList=list.filter(one => {
              return one.year_num == item
            });
        }

      },
      choseType(index,item){
        this.typeIndex=index;
        this.contentList=item.list
      },
      fixWidth() {
        this.$nextTick(() => {
          this.ulBoxwidth = this.$refs.atlasulBox.offsetWidth;
          this.left3 = this.left2 = this.ulBoxwidth
        })
      },
      getTypeList() {
        this.$init.getChannelList(channelList => {
          let typeList = channelList.filter(item => {
            return item.parent_id == this.typeid
          });
          this.typeList = typeList;
          this.getData()
        })
      },
      getData() {
        let api = '?m=api&c=v1.Api&a=index&_ajax=1';
        this.$request.post(api, {
          apiArclist_1: `ekey=1&typeid=${this.typeid}&limit=100&addfields=pdfurl,year_num`, //获取栏目文章列表
        }).then(res => {
          this.newsList = res.apiArclist[1].data;
          let newsMap = this.$utils.arrayChangeKey(this.newsList, 'typeid', true);
           let newsYear = this.$utils.arrayChangeKey(this.newsList, 'year_num', true);

           this.newsYear=[]
           for(var i in newsYear){
            this.newsYear.push(i)
           }
           this.newsYear=this.numsort(this.newsYear)

           console.log(this.newsYear)
          let newsData = [];
          this.typeList.forEach(item => {
            let _item = {
              name: item.typename,
              list: newsMap[item.id]
            };
            newsData.push(_item)
          })

          this.yearList = newsData;
           this.contentList= this.yearList[0].list
          this.fixWidth();
        })
      },

    numsort(a){
      for(var i=1; i<a.length; i++)                             // 外层循环元素下标为1~9
          {
              for(var j=a.length; j>=i; j--)                         // 内层循环元素下标为i~9
              {
                  if(a[i] > a[j-1])                       // 如果前一个数比后一个数大，交换数据
                  {
                      var iTemp = a[j-1];
                      a[j-1] = a[j];
                      a[j] = iTemp;
                  }
              }
          }
        return  a
    },
      moveLeft() {
        if (this.indexOne == 1) {
          this.left1 = -this.ulBoxwidth
          this.indexOne = 2
        } else if (this.indexOne == 2) {
          this.left1 = -(this.ulBoxwidth * 2)
          this.indexOne = 3
        }
      },
      moveRight() {
        if (this.indexOne == 2) {
          this.left1 = 0
          this.indexOne = 1
        } else if (this.indexOne == 3) {
          this.left1 = -this.ulBoxwidth
          this.indexOne = 2
        }
        // let ulBoxwidth = this.$refs.atlasulBox.offsetWidth;
        // let atlasulWidth = this.$refs.atlasul.offsetWidth;
        // let moveWith = ulBoxwidth - atlasulWidth;
        // if (this.leftMove < 0) {
        //   // 假如要移动的宽度大于可显示的宽度就分段移动
        //   if (Math.abs(moveWith) > ulBoxwidth) {
        //     let Move = Math.abs(moveWith - this.leftMove)
        //     if (Math.abs(Move) < ulBoxwidth) {
        //       this.leftMove = this.leftMove + ulBoxwidth
        //     } else {
        //       this.leftMove = 0
        //     }
        //   } else {
        //     // 假如要移动的距离小于可显示区域，就移动要移动的距离
        //     this.leftMove = 0
        //   }
        // }
      },
      choseYears(index) {
        this.yearIndex = index
      },
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style lang="less" scoped>
  .WJgroupHonor-taxisother {
    position: relative;
    margin-bottom: 100px;
  }

  ::v-deep .swiper-pagination-bullet-active {
    background-color: #b08e68 !important;
  }


  .WJgroupHonor-taxisType::after {
    content: "";
    position: absolute;
    top: 6px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #B08E68;
  }

  .WJgroupHonor-taxisType::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 50%;
    margin-left: -6px;
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    // border: 1px solid #B08E68;
    border-radius: 50%;
    background: #B08E68;
    border-radius: 50%;
  }

  .WJgroupHonor-taxisType {
    padding-top: 40px;
    font-size: 38px;
    font-family: Impact;
    font-weight: 600;
    color: #B08E68;
    line-height: 45px;
    text-align: center;
    position: relative;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  .gIntroduce-banner {
    width: 100%;
    height: auto;
  }

  .gIntroduce-banner img {
    width: 100%;
    height: auto;
  }



  .WJgroupIntroduce-title {
    text-align: center;
    position: relative;
    padding-bottom: 140px !important;
  }

  .WJgroupIntroduce-titleIMg {
    width: 100%;
    height: 148px;
    position: absolute;
    bottom: 0px;
    left: 0;
  }

  .WJgroupIntroduce-titleIMg img {
    height: 100%;
  }

  .WJgroupIntroduce-title h1 {
    font-size: 40px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1F2D3A;
    line-height: 68px;
  }

  .WJgroupIntroduce-title h2 {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1F2D3A;
    line-height: 68px;
  }

  .WJgroupHonor-time {
    margin-bottom: 120px;
  }

  .WJgroupHonor-time .WJgroupHonor-taxis {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 460px;
    position: relative;
    margin-bottom: 70px;
  }

  .WJgroupHonor-taxisbox {
    width: auto;
    margin-left: 50px;
    margin-right: 50px;
    height: 100%;
    overflow: hidden;
    overflow-x: scroll;
    position: relative;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .WJgroupHonor-Icon {
    width: 41px;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    // border: 2px solid #b08e68;
    border-radius: 50%;
    position: absolute;
    bottom: -0px;
    cursor: pointer;
    transition: 1s;
  }

  .WJgroupHonor-Icon:hover {
    background-color: #b08e68;

  }

  .WJgroupHonor-Icon:hover i {
    color: #ffffff !important;

  }

  .WJgroupHonor-Icon i {
    color: #b08e68;
    transition: 1s;
    font-size: 30px
  }

  .WJgroupHonor-leftIcon {
    left: 36%;
  }

  .WJgroupHonor-rightIcon {
    right: 36%;
  }

  .WJgroupHonor-time .WJgroupHonor-taxisul {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    transition: 0.5s;
  }

  .WJgroupHonor-time .WJgroupHonor-taxisul:before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #B08E68;
    position: absolute;
    bottom: 74px;
    left: 0px;
  }



  .WJgroupHonor-time .WJgroupHonor-taxisul li {

    height: 100%;
    position: relative;
    cursor: pointer;
    padding: 0 20px;
  }

  .WJgroupHonor-time .WJgroupHonor-taxisul li .WJgroupHonor-taxiname {
    position: absolute;
    bottom: 16px;
    left: 0px;
    font-size: 38px;
    font-family: Impact;
    font-weight: 600;
    color: #CCCCCC;
    line-height: 45px;
  }

  .WJgroupHonor-time .WJgroupHonor-taxisul li .WJgroupHonor-taxisActiveDiv {
    color: #B08E68;
  }

  .WJgroupHonor-taxiQuan {
    position: absolute;
    bottom: 68px;
    left: 0px;
    // margin-left: -6px;
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    border: 1px solid #B08E68;
    border-radius: 50%;

  }

  .WJgroupHonor-taxiActiveQuan {
    background: #B08E68;
    border-radius: 50%;
  }

  .nativeContent-ulBox {
    width: 100%;
    // padding: 70px 0;
  }

  .nativeContent-ulBox .nativeContent-ul {
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .nativeContent-ulBox .nativeContent-ul li {
    width: 240px;
    padding: 0 16px;
    margin-bottom: 20px;
  }

  .nativeContent-ulBox .nativeContent-ul li p {

    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 34px;
  }

  .nativeContent-ulBox .nativeContent-ul li div {
    width: 100%;
    height: 320px;
    position: relative;
    margin-top: 10px;
  }

  .nativeContent-ulBox .nativeContent-ul li div img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.5s;
  }

  .nativeContent-ulBox .nativeContent-ul li:hover p {
    color: #B08E68;
  }

  .nativeContent-ulBox .nativeContent-ul li:hover div img {
    top: -10px;
  }


  // **********************************************
  .articlesType {
    width: 248px;
  }

  .articlesType li {
    font-weight: 400;
    font-size: 29px;
    line-height: 45px;
    color: #A6A6A6;
    padding: 15px 0;
    margin-bottom: 60px;
    cursor: pointer;
  }

  .activeAtype {
    color: #B08E68 !important;
    border-bottom: 2px solid #B08E68;

    .activeAName {
      position: relative;
    }

    .activeAtSpan {
      position: absolute;
      top: 12px;
      right: -26px;
      width: 0;
      height: 0;
      border-left: 10px solid #B08E68;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }

  }

  .articlesContent {
    width: calc(100% - 250px);
    margin-left: 146px;
  }

  .articlesYears {
    border-bottom: 1px solid #EBEBEB;

    .articlesYName {
      padding: 17px 0;
      width: 116px;
      font-size: 24px;
      line-height: 42px;
      text-align: center;
      color: #B1A190;
      list-style: none;
      cursor: pointer;
    }
  }

  .activeY {
    font-weight: 400;
    font-size: 24px;
    color: #A4825F;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 116px;
      height:4px;
      background: #A4825F;
    }
  }

  .articlesList {
    width: 100%;
    flex-wrap: wrap;

  }

  .articlesList li {
    width: 24%;
    margin-top: 58px;
    cursor: pointer;
       transition: 1s;
       &:nth-child(3n-1){
         margin: 58px 10% 0;
       }
       &:nth-child(3n-2){
         margin: 58px 0% 0 2%;
       }
  }

  .articlesList li div {
    width: 100%;

  }

  .articlesList li div img {
    width: 100%;
  }

  .articlesList li p {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 34px;
    text-align: center;
    margin-top: 10px;
  }

  .articlesList li:hover {
        transform: translate(0,-10px);
  }
</style>
